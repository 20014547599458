/*
 *    Global Styles
 */

:root {
  --margin-size: 8px;
  --global-padding: 24px;

  --mdc-filled-button-label-text-tracking: normal;
  --mdc-outlined-button-label-text-tracking: normal;
  --mdc-text-button-label-text-tracking: normal;
  --mdc-protected-button-label-text-tracking: normal;

  --mdc-filled-button-container-height: 42px;
  --mdc-text-button-container-height: 42px;
  --mdc-outlined-button-container-height: 42px;
  --mdc-raised-button-container-height: 42px;
  --mdc-protected-button-container-height: 42px;

  --mat-card-title-text-size: 20px;
  --mat-table-header-headline-size: 12px;
  --mat-table-header-headline-color: #0000008a;
  --mat-sort-arrow-color: #0000008a;
  --mat-table-row-item-container-height: 42px;
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);

  --mat-divider-color: rgba(0, 0, 0, 0.12);

  --mdc-filled-text-field-container-color: rgba(0, 0, 0, 0.05);
  --mdc-outlined-text-field-disabled-outline-color: transparent;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.15);
  --mat-form-field-container-text-size: 14px;
  --mat-form-field-container-vertical-padding: 9px;
  --mat-form-field-container-text-tracking: 1;
  --mdc-outlined-text-field-label-text-size: 14px;

  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-hover-state-layer-color: rgb(205, 205, 205);
  --mdc-list-list-item-hover-state-label-text-color: black;
  --mdc-list-list-item-focus-state-layer-color: transparent;
  --mdc-list-list-item-focus-state-layer-opacity: 0;
  --mdc-list-list-item-hover-state-label-text-color: black;
  --mdc-list-list-item-label-text-tracking: normal;

  .active-link {
    --mdc-list-list-item-label-text-color: #0062a1;
    --mdc-list-list-item-hover-label-text-color: #0062a1;
    --mdc-list-list-item-focus-label-text-color: #0062a1;
    --mdc-list-list-item-active-label-text-color: #0062a1;

    &.link {
      background-color: transparent !important;
      span {
        font-weight: bold !important;
      }
    }
  }

  --mat-expansion-header-text-tracking: normal;
  --mat-expansion-container-text-tracking: normal;
  --mdc-dialog-supporting-text-tracking: normal;
  --mdc-outlined-text-field-label-text-tracking: normal;
  --mat-toolbar-title-text-tracking: normal;
  --mat-legacy-button-toggle-label-text-tracking: normal;
  --mat-standard-button-toggle-label-text-tracking: normal;
  --mat-bottom-sheet-container-text-tracking: normal;
  --mat-table-header-headline-tracking: normal;
  --mat-table-footer-supporting-text-tracking: normal;
  --mdc-extended-fab-label-text-tracking: normal;
  --mat-checkbox-label-text-tracking: normal;
  --mat-paginator-container-text-tracking: normal;
  --mdc-list-list-item-supporting-text-tracking: normal;
  --mdc-list-list-item-trailing-supporting-text-tracking: normal;
  --mat-menu-item-label-text-tracking: normal;
  --mdc-slider-label-label-text-tracking: normal;
  --mat-radio-label-text-tracking: normal;
  --mdc-dialog-subhead-tracking: normal;
  --mat-select-trigger-text-tracking: normal;
  --mdc-filled-text-field-label-text-tracking: normal;
  --mat-form-field-subscript-text-tracking: normal;
  --mdc-plain-tooltip-supporting-text-tracking: normal;
  --mat-card-title-text-tracking: normal;
  --mat-card-subtitle-text-tracking: normal;
  --mat-optgroup-label-text-tracking: normal;
  --mat-option-label-text-tracking: normal;
  --mat-table-header-headline-tracking: normal;
  --mat-table-row-item-label-text-tracking: normal;
  --mat-table-footer-supporting-text-tracking: normal;

  --mat-standard-button-toggle-label-text-size: 14px;

  .mat-mdc-progress-bar {
    --mdc-linear-progress-active-indicator-color: #1d1b85;
    --mdc-linear-progress-track-color: rgb(39, 58, 179);
  }

  .export-button {
    .mat-mdc-progress-bar {
      --mdc-linear-progress-active-indicator-color: #28a745;
      --mdc-linear-progress-track-color: #58d275;
    }
  }

  --mat-standard-button-toggle-selected-state-background-color: #f7f9fc;
  --mat-standard-button-toggle-selected-state-background-color: rgba(79, 71, 148, 0.1);

  --mdc-elevated-card-container-shape: 12px;
  --mdc-elevated-card-container-elevation: 0 0 1px 1px #dfe3ea, 0px 5px 5px 3px rgba(0, 0, 0, 0.05),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  --mat-tree-node-min-height: 40px;
}

.standard-padding {
  padding: 12px;
}

.global-padding {
  padding: var(--global-padding);
}

.bi-padding {
  padding: var(--margin-size);
}

.bi-margin {
  margin: var(--margin-size);
}

.bi-margin-top {
  margin-top: var(--margin-size);
}

.bi-margin-bottom {
  margin-bottom: var(--margin-size);
}

.bi-margin-horizontal {
  margin-left: var(--margin-size);
  margin-right: var(--margin-size);
}

.bi-margin-vertical {
  margin-top: var(--margin-size);
  margin-bottom: var(--margin-size);
}

.card {
  overflow: hidden;
  background-color: white;
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
  border-bottom-left-radius: 16px !important;
  box-shadow: 0 6px 10px rgb(16 30 74 / 4%), 0 1px 18px rgb(16 30 74 / 3%) !important;
}

.subtitle {
  font-weight: 600;
  opacity: 0.6;
  font-size: 16px;
}

.chip {
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  height: 28px;
  // margin-right: 16px;

  span {
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 2px;
    margin-bottom: 2px;
    text-align: center;
    font-size: 12px;
    line-height: 11px;
    font-weight: 600;
    // color: red;
  }
}

td .chip {
  margin-right: 16px;
}

.export-button {
  color: white;
  background-color: rgb(38, 138, 80);
}

.save-button {
  background-color: rgb(6, 95, 189);
  color: white;
}

// Add small padding to all material table cells
// if !important ends up being problematic, remove this section and apply it in component CSS instead
td.mat-mdc-cell,
th.mat-mdc-header-cell {
  padding: 2px 3px !important;
}

td.mat-mdc-cell:first-of-type,
th.mat-mdc-header-cell:first-of-type {
  padding-left: 24px !important;
}

//////

.dialog-loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100%);
  height: calc(100%);
  background-color: rgba(255, 255, 255, 0.9);
  text-align: center;
  pointer-events: none;
}

.general-error {
  color: red;
}

.admin-screen-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100%;
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

.admin-screen-sidebar {
  width: 220px;
  margin: 0 16px;

  .sidebar-title {
    color: #171c21;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin-top: 15px;
    margin-left: 19px;
    margin-bottom: 24px;
  }
}

.admin-screen-content {
  flex-grow: 1;
}

table {
  width: 100%;

  .hidden {
    display: none;
  }

  .clickable {
    transition: background ease-out 100ms;
    cursor: pointer;

    &:hover {
      background: #00639b11;
    }
  }
}

.screen-title {
  font-size: 22px;
  margin-top: 20px;
  margin-bottom: 24px;
  font-weight: 600;
}
