@use './styles/theme.scss' as themeVariables;
@use '@angular/material' as mat;

@import url('https://fonts.googleapis.com/css?family=Mulish:300,400,500,600,700,800,900');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

$irj-typography: mat.m2-define-typography-config(
  $font-family: 'Mulish',
);

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($irj-typography);`
@include mat.all-component-typographies($irj-typography);
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$irj-website-primary: mat.m2-define-palette(themeVariables.$irj-dark-blue);
$irj-website-secondary: mat.m2-define-palette(themeVariables.$irj-cyan);
$irj-website-accent: mat.m2-define-palette(themeVariables.$irj-green);
$irj-website-warn: mat.m2-define-palette(themeVariables.$irj-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".

//  This theme demonstrates the ability to quickly change the site theme.
// $irj-website-primary: mat-palette(themeVariables.$irj-cyan);
// $irj-website-secondary: mat-palette(themeVariables.$irj-dark-blue);
// $irj-website-accent: mat-palette(themeVariables.$irj-red);
// $irj-website-warn: mat-palette(themeVariables.$irj-green);

$irj-website-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $irj-website-primary,
      secondary: $irj-website-secondary,
      accent: $irj-website-accent,
      warn: $irj-website-warn,
    ),
    typography: $irj-typography,
    density: -4,
  )
);

/*
        typography:
            mat.define-typography-config(
                $font-family: 'Mulish'
            )
*/

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

@include mat.all-component-themes($irj-website-theme);

/* You can add global styles to this file, and also import other style files */
$toolbar-background: mat.m2-get-color-from-palette($irj-website-primary);

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

@import './styles//global.scss';

.request-info-box {
  display: none !important;
}

.disabled {
  .mdc-list-item {
    pointer-events: none !important;
    opacity: 0.5;
    .mdc-list-item__content {
      pointer-events: none !important;
      opacity: 0.5;
    }
  }
}
